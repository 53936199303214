import React, { useState } from 'react';
import './ChatbotModal.css';

function ChatbotModal() {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div className="chatbot-modal">
      {isChatOpen && (
        <div className="chatbot-container">
          <button className="close-button" onClick={toggleChat}>X</button>
          <div className="chatbot-content">
            <h2 className="chatbot-title">
            Compliance Copilot
            </h2>
            <p>Welcome! How can I assist you with your compliance needs today?</p>
            <input type="text" className="chat-input" placeholder="Type your message..." />
          </div>
        </div>
      )}
      <button className="chat-toggle-button" onClick={toggleChat}>
        {isChatOpen ? 'Close Chat' : 'Compliance Copilot'}
      </button>
    </div>
  );
}

export default ChatbotModal;

