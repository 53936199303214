import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { getColor, calculateScorePercentage } from './utils';
import TestCard from './TestCard';


const RiskAreaCard = ({ id, riskAreaName, child_measures = [], propagateRiskAreaData, initialRiskAreaData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [totalScore, setTotalScore] = useState(initialRiskAreaData.get("score"));
  const [subScores, setSubScores] = useState(); // eslint-disable-line no-unused-vars
  const [riskAreaData, setRiskAreasData] = useState(initialRiskAreaData);
  const updateRiskAreaData = (testName, testData) => {
    setRiskAreasData(prevMap => new Map(prevMap).set(testName, testData));
  };
  
  useEffect(() => {
    propagateRiskAreaData(riskAreaName, riskAreaData); // used to propagate the score to the parent component
  }, [riskAreaName, totalScore, riskAreaData, propagateRiskAreaData]); // This effect runs whenever these variables change

  const updateRiskAreaScore = (testName, testScore) => { // function for sub-components to update the score
    // console.log(`updateRiskAreaScore by ${testName} with ${testScore}`)
    setSubScores(prevScores => {
      const newScores = { ...prevScores, [testName]: testScore };
      const sumScores = Object.values(newScores).reduce((a, b) => a + (b || 0), 0);
      const avgScore = sumScores / Object.keys(newScores).length;  // Calculate the average
      setTotalScore(avgScore);  // Set the average score
      return newScores;
    });
  };

  const propagateTestData = useCallback((testName, testData) => { // function for sub-components to update the score
    updateRiskAreaScore(testName, testData.get("score"));
    updateRiskAreaData(testName, testData);
    updateRiskAreaData("score", totalScore);
  }, [totalScore]);

  return (
    <div className="flex flex-col bg-gray-100 p-4 mb-2 rounded">
      <div className="flex justify-between items-center mb-4" onClick={() => setIsOpen(!isOpen)}>
        <h4 className="text-gray-800 text-base">{riskAreaName}</h4>
        <div className="flex items-center">
          {/* Score indicator placed here, made smaller with w-16 */}
          <div className="w-20 h-2 rounded-full overflow-hidden mr-2 bg-gray-200">
            <div className={`${getColor(totalScore)} w-full h-full rounded-full`} style={{ width: calculateScorePercentage(totalScore) }}></div>
          </div>
          {/* Toggle icon */}
          {isOpen ? <FaChevronDown className="text-lg" /> : <FaChevronRight className="text-lg" />}
        </div>
      </div>
      {isOpen && child_measures.map((item) => (
        <TestCard
          key={id + "_" + item.testName}
          id={id + "_" + item.testName}
          testName={item.testName}
          displayName={item.name}
          propagateTestData={propagateTestData}
          initialTestData={riskAreaData.get(item.testName) ? riskAreaData.get(item.testName) : new Map([])}
        />
      ))}
    </div>
  );
}

export default RiskAreaCard;