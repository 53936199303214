import React, { useState } from 'react';

function ClassificationModal({ isVisible, onClose, onSurveyClick, onChatbotClick }) {
  const [description, setDescription] = useState('');
  const [riskClassification, setRiskClassification] = useState('');
  const [assessmentMethod, setAssessmentMethod] = useState('');

  if (!isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-container">
        <button className="close-button" onClick={onClose}>X</button>
        <div className="modal-content">
          <h2 className="modal-title">Setup New AI Use-Case</h2>

          <div className="modal-section">
            <h3>Description</h3>
            <textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="modal-textarea"
              placeholder="Enter a description of the use-case"
            />
          </div>

          <div className="modal-section">
            <h3>Risk Classification</h3>
            <button onClick={onSurveyClick} className="modal-button">Go to Survey</button>
            <button onClick={onChatbotClick} className="modal-button">Open Chatbot</button>
            <select
              id="riskClassification"
              value={riskClassification}
              onChange={(e) => setRiskClassification(e.target.value)}
              className="modal-select"
            >
              <option value="">Select risk classification</option>
              <option value="Low">Limited Risk</option>
              <option value="Medium">High Risk</option>
              <option value="High">Prohibited</option>
              <option value="High">GPAI</option>
            </select>
          </div>

          <div className="modal-section">
            <h3>Technical AI Information</h3>
            <button className="modal-button">Link Dataset</button>
            <button className="modal-button">Link Model</button>
          </div>

          <div className="modal-section">
            <h3>Assessment Method</h3>
            <select
              id="assessmentMethod"
              value={assessmentMethod}
              onChange={(e) => setAssessmentMethod(e.target.value)}
              className="modal-select"
            >
              <option value="">Select assessment method</option>
              <option value="Dekra Assessment">EU AI Act Assessment</option>
              <option value="CertifAI Assessment">CertifAI Assessment</option>
              <option value="Dekra Assessment">Dekra Assessment</option>
              <option value="Dekra Assessment">ISO PAS 8800</option>
            </select>
          </div>

          <div className="modal-section">
            <button className="execute-assessment-button">Execute Assessment</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassificationModal;
