import React, { useState, useEffect, useCallback } from 'react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { getColor, calculateScorePercentage } from './utils';
import RiskAreaCard from './RiskAreaCard';

const RiskDimensionCard = ({ id, dimensionName, risk_areas = [], propagateDimensionData, initialDimensionData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [totalScore, setTotalScore] = useState(initialDimensionData.get("score"));
  const [subScores, setSubScores] = useState({}); // eslint-disable-line no-unused-vars
  const [dimensionData, setDimensionData] = useState(initialDimensionData);
  const updateDimensionData = (riskAreaName, riskAreaData) => {
    setDimensionData(prevMap => new Map(prevMap).set(riskAreaName, riskAreaData));
  };

  useEffect(() => {
    propagateDimensionData(dimensionName, dimensionData); // used to propagate the score to the parent component
  }, [dimensionName, totalScore, dimensionData, propagateDimensionData]); // This effect runs whenever these variables change

  const updateDimensionScore = (riskAreaName, riskAreaScore) => { // function for sub-components to update the score
    setSubScores(prevScores => {
      const newScores = { ...prevScores, [riskAreaName]: riskAreaScore };
      const sumScores = Object.values(newScores).reduce((a, b) => a + (b || 0), 0);
      const avgScore = sumScores / Object.keys(newScores).length;  // Calculate the average
      setTotalScore(avgScore);  // Set the average score
      return newScores;
    });
  };

  const propagateRiskAreaData = useCallback((riskAreaName, riskAreaData) => { // function for sub-components to update the score
    updateDimensionScore(riskAreaName, riskAreaData.get("score"));
    updateDimensionData(riskAreaName, riskAreaData);
    updateDimensionData("score", totalScore);
  }, [totalScore]);

  return (
    <div className="bg-white p-6 rounded shadow-md mb-6 cursor-pointer text-left">
      <div className="flex justify-between items-center mb-4" onClick={() => setIsOpen(!isOpen)}>
        <h2 className="text-gray-800 text-lg font-bold">{dimensionName}</h2>
        <div className="flex items-center">
          {/* Score indicator placed here, made smaller with w-16 */}
          <div className="w-20 h-2 rounded-full overflow-hidden mr-2 bg-gray-200">
            <div className={`${getColor(totalScore)} w-full h-full rounded-full`} style={{ width: calculateScorePercentage(totalScore) }}></div>
          </div>
          {/* Toggle icon */}
          {isOpen ? <FaChevronDown className="text-lg" /> : <FaChevronRight className="text-lg" />}
        </div>
      </div>
      {isOpen && risk_areas.map((risk_area) => (
        <RiskAreaCard
          key={id + "_" + risk_area.name}
          id={id + "_" + risk_area.name}
          riskAreaName={risk_area.name}
          child_measures={risk_area.child_measures}
          propagateRiskAreaData={propagateRiskAreaData}
          initialRiskAreaData={dimensionData.get(risk_area.name) ? dimensionData.get(risk_area.name) : new Map([])}
        />
      ))}
    </div>
  );
};

export default RiskDimensionCard;
