// utils.js

export const getColor = (result) => {

    const score = result * 10;

    if (result === null || result === undefined) {
      return 'bg-gray-400'; // Grey for unexecuted tests
    } else if (score <= 3) {
      return 'bg-red-500'; // Red for scores 1-3
    } else if (score <= 7) {
      return 'bg-yellow-500'; // Yellow for scores 4-7
    } else {
      return 'bg-green-500'; // Green for scores 8-10
    }
  };

// Function to calculate score percentage for the bar width
export const calculateScorePercentage = (score) => {
    return score ? `${score * 100}%` : '0%'; // Assuming score is out of 10
    };