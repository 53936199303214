export const initialUseCaseConfig = new Map([
  ["Classifying Hand-Written Numbers", {
    risk_classification: "High",
    risk_dimensions: [
      {name: "Fairness",
       risk_areas: [
          {name: "Fairness among groups", child_measures: []},
          {name: "Managing Fairness during Operations", child_measures: []}
        ]
      },
      {name: "Autonomy & Control",
        risk_areas: [
          {name: "Autonomy of the AI System and Possibility of Human Intervention", child_measures: []}
        ]
      },
      {name: "Transparency",
        risk_areas: [
          {name: "Transparency for Users", child_measures: []},
          {name: "Transparency for Developers", child_measures: []},
          {name: "Auditability", child_measures: []},
          {name: "Managing Transparency during Operations", child_measures: []}
        ]
      },
      {name: "Reliability",
        risk_areas: [
          {name: "Regular operations", child_measures: [
            {name:"MNIST Test", testName: "MNISTTest"},
          ]},
          {name: "Robustness", child_measures: []},
          {name: "Managing Out-of-Distribution Input", child_measures: []},
          {name: "Uncertainty Estimation", child_measures: []},
          {name: "Managing Reliability during Operations", child_measures: []},
      ]},
      {name: "Security",
        risk_areas: [
          {name: "Functional Security", child_measures: []},
          {name: "Integrity and Availability", child_measures: []},
          {name: "Managing Security during Operations", child_measures: []}
        ]},
      {name: "Privacy",
        risk_areas: [
          {name: "Protection of Personal Data", child_measures: []},
          {name: "Protection of Business-Relevant Data", child_measures: []},
          {name: "Managing Privacy during Operations", child_measures: []}
        ]},
      {name: "Sustainability",
        risk_areas: [
          {name: "Description about Privacy", child_measures: []}
        ]}
  ]}],

  ["Autonomous Driving Object Detection", {
    risk_classification: "High",
    risk_dimensions: [
      {name: "Fairness",
        risk_areas: [
          {name: "Fairness among groups", child_measures: []},
          {name: "Managing Fairness during Operations", child_measures: []}
        ]
      },
      {name: "Autonomy & Control",
        risk_areas: [
          {name: "Autonomy of the AI System and Possibility of Human Intervention", child_measures: []}
        ]
      },
      {name: "Transparency",
        risk_areas: [
          {name: "Transparency for Users", child_measures: []},
          {name: "Transparency for Developers", child_measures: []},
          {name: "Auditability", child_measures: []},
          {name: "Managing Transparency during Operations", child_measures: []}
        ]
      },
      {name: "Reliability",
        risk_areas: [
          {name: "Regular operations", child_measures: [
            {name:"Accuracy", testName: "YOLOPPerformanceTest"},
            {name:"DuplicatesCheck", testName: "LatticeFlowDuplicatesTest"},
            {name:"Data Leakage", testName: "LatticeFlowDataLeakageTest"},
            {name:"Label Errors", testName: "LatticeFlowLabelMistakesTest"},
          ]},
          {name: "Robustness", child_measures: []},
          {name: "Managing Out-of-Distribution Input", child_measures: []},
          {name: "Uncertainty Estimation", child_measures: []},
          {name: "Managing Reliability during Operations", child_measures: []},
      ]},
      {name: "Security",
        risk_areas: [
          {name: "Functional Security", child_measures: []},
          {name: "Integrity and Availability", child_measures: []},
          {name: "Managing Security during Operations", child_measures: []}
        ]},
      {name: "Privacy",
        risk_areas: [
          {name: "Protection of Personal Data", child_measures: []},
          {name: "Protection of Business-Relevant Data", child_measures: []},
          {name: "Managing Privacy during Operations", child_measures: []}
        ]},
      {name: "Sustainability",
        risk_areas: [
          {name: "Description about Privacy", child_measures: []}
        ]}
  ]}],
    
  ["Predicting Housing Prices", {
    risk_classification: "Limited",
    risk_dimensions: [
      {name: "Fairness",
        risk_areas: [
          {name: "Fairness among groups", child_measures: []},
          {name: "Managing Fairness during Operations", child_measures: []}
        ]
      },
      {name: "Autonomy & Control",
        risk_areas: [
          {name: "Autonomy of the AI System and Possibility of Human Intervention", child_measures: []}
        ]
      },
      {name: "Transparency",
        risk_areas: [
          {name: "Transparency for Users", child_measures: [
            {name:"Feature Importance Test", testName: "XGBoostFeatureImportanceTest"},
            {name:"Shapley Analysis", testName: "XGBoostSHAPTest"}
          ]},
          {name: "Transparency for Developers", child_measures: []},
          {name: "Auditability", child_measures: []},
          {name: "Managing Transparency during Operations", child_measures: []}
        ]
      },
      {name: "Reliability",
        risk_areas: [
          {name: "Regular operations", child_measures: [
            {name:"Accuracy", testName: "XGBoostTest"},
            {name:"Confidence Interval", testName: "XGBoostConfidenceIntervalTest"},
            {name:"ODD Coverage", testName: "XGBoostODDCoverageTest"},
            {name:"Out-of-domain (Trainingset)", testName: "XGBoostO3D3TrainingTest"},
            {name:"Out-of-domain (Testset)", testName: "XGBoostO3D3TestingTest"},
            {name:"Data Drift", testName: "DataDriftTest"}
          ]},
          {name: "Robustness", child_measures: []},
          {name: "Managing Out-of-Distribution Input", child_measures: []},
          {name: "Uncertainty Estimation", child_measures: []},
          {name: "Managing Reliability during Operations", child_measures: []},
      ]},
      {name: "Security",
        risk_areas: [
          {name: "Functional Security", child_measures: []},
          {name: "Integrity and Availability", child_measures: []},
          {name: "Managing Security during Operations", child_measures: []}
        ]},
      {name: "Privacy",
        risk_areas: [
          {name: "Protection of Personal Data", child_measures: []},
          {name: "Protection of Business-Relevant Data", child_measures: []},
          {name: "Managing Privacy during Operations", child_measures: []}
        ]},
      {name: "Sustainability",
        risk_areas: [
          {name: "Description about Privacy", child_measures: []}
        ]}
  ]}],

  ["Evaluating Conversational Chatbots", {
    risk_classification: "Limited",
    risk_dimensions: [
      {name: "Fairness",
        risk_areas: [
          {name: "Fairness among groups", child_measures: []},
          {name: "Managing Fairness during Operations", child_measures: []}
        ]
      },
      {name: "Autonomy & Control",
        risk_areas: [
          {name: "Autonomy of the AI System and Possibility of Human Intervention", child_measures: []}
        ]
      },
      {name: "Transparency",
        risk_areas: [
          {name: "Transparency for Users", child_measures: []},
          {name: "Transparency for Developers", child_measures: []},
          {name: "Auditability", child_measures: []},
          {name: "Managing Transparency during Operations", child_measures: []}
        ]
      },
      {name: "Reliability",
        risk_areas: [
          {name: "Regular operations", child_measures: []},
          {name: "Robustness", child_measures: []},
          {name: "Managing Out-of-Distribution Input", child_measures: []},
          {name: "Uncertainty Estimation", child_measures: []},
          {name: "Managing Reliability during Operations", child_measures: []},
      ]},
      {name: "Security",
        risk_areas: [
          {name: "Functional Security", child_measures: []},
          {name: "Integrity and Availability", child_measures: []},
          {name: "Managing Security during Operations", child_measures: []}
        ]},
      {name: "Privacy",
        risk_areas: [
          {name: "Protection of Personal Data", child_measures: []},
          {name: "Protection of Business-Relevant Data", child_measures: []},
          {name: "Managing Privacy during Operations", child_measures: []}
        ]},
      {name: "Sustainability",
        risk_areas: [
          {name: "Description about Privacy", child_measures: []}
        ]}
  ]}]
]);

export const initialActiveUseCase = 'UC1: Computer Vision (MNIST)';
