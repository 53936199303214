import React, { useState, useEffect } from 'react';
import { getColor, calculateScorePercentage } from './utils';
import axios from 'axios';

const apiUrl_base = process.env.REACT_APP_BACKEND_APP_API_URL || 'http://127.0.0.1:8000';

const TestCard = ({ id, testName, displayName, propagateTestData, initialTestData }) => {
  const [loading, setLoading] = useState(false);
  const [testData, setTestData] = useState(initialTestData);
  const updateTestData = (key, value) => {
    setTestData(prevMap => new Map(prevMap).set(key, value));
  };
  const [error, setError] = useState(null);
  const [isAccepted, setIsAccepted] = useState(true); // if the test result is accepted (! for some reason this is reversed in the beginning)

  useEffect(() => {
    propagateTestData(testName, testData); // used to propagate the score to the parent component
  }, [testName, testData, propagateTestData]); // This effect runs whenever these variables change

  const fetchTestResult = async (event) => {
    event.stopPropagation(); // This stops the click event from bubbling up to parent elements
  
    if (loading) return; // Prevents multiple clicks during loading

    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${apiUrl_base}/api/testmanager/run_test?test_id=${testName}`);
      // console.log(response); // For demonstration purposes
      if (response.data.data && (response.data.data.result !== undefined)) { // Assuming the API returns data in this structure
        updateTestData("score", response.data.data.result);
      }
      if (response.data.data && response.data.data.image_data) {
        updateTestData("image_id", response.data.data.image_data);
      }
      if (response.data.data && response.data.data.external_link) {
        updateTestData("external_link", response.data.data.external_link);
      }
      if (response.data.data && response.data.data.message) {
        console.log(response.data.data.message);
        updateTestData("message", response.data.data.message);
      }
    } catch (error) {
      console.error(`Error fetching ${testName} result:`, error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  // const [isChecked, setIsChecked] = useState(false); // if the test result is accepted
  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  //   if (isChecked) {
  //     updateTestData("score", 1)
  //   }
  //   else {
  //     updateTestData("score", 0)
  //   }
  // };

  function PlotImage(image_id) {
    // Append a timestamp to the URL
    const imageUrl = `${apiUrl_base}/api/testmanager/fetch_image/${image_id}`;

    return (
      <div>
        <img key={image_id} src={imageUrl} alt="Result Plot" style={{ maxWidth: '1000px' }}/>
      </div>
    );
  }

  const [showIframe, setShowIframe] = useState(false);
  function IframeComponent({ url }) {
    if (!showIframe) {
      return null;
    }
    return (
      <div className="iframe-container">
        <button onClick={() => setShowIframe(false)} className="close-button">X</button>
        <iframe src={url} className="fullsize-iframe" title="External Content"></iframe>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-white p-4 mb-2 rounded shadow-md cursor-pointer">
      <div className="flex justify-between items-start">
        <h4 className="text-gray-800 text-base flex-1" onClick={fetchTestResult}>{displayName}</h4>
        <div className="flex items-start flex-none">
          {testData.has("message") && (
            <div style={{ width: '500px', whiteSpace: 'pre-wrap', wordWrap: 'break-word', marginLeft: '10px', marginRight: '10px'}}>
              {testData.get("message").replace(/\\n/g, '\n')}
            </div>
          )}
          {testData.has("image_id") && PlotImage(testData.get("image_id"))}
          {testData.has("external_link") && !showIframe && (
            <div className="mr-20 underline text-blue-600 hover:text-red-600">
              <a href={testData.get("external_link")} target="_blank" rel="noopener noreferrer">Link to Testprovider</a>
            </div>
            // <button className="mr-5 underline text-blue-600 hover:text-red-600" onClick={() => setShowIframe(true)}>Link to Testprovider </button>
          )}
          {testData.has("external_link") && showIframe && (
            <div>
              <IframeComponent url={testData.get("external_link")} />
            </div>
          )}
          {loading && (
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          )}
          <div className="flex items-center">
            {error && <span className="text-red-500 mr-2">Error occurred</span>}
            {/* Score indicator placed here, made smaller with w-16 */}
            {/* This is where we will align the result to the right */}
            {testData.has("score") && !(testData.has("image_id") || testData.has("external_link")) && (
            <div className="mr-4 text-gray-800 flex-none">
              {`Result: ${testData.get("score")}`}
            </div>
            )}

            <div className="flex-col">
              <div className="w-20 h-2 rounded-full overflow-hidden mr-2 bg-gray-200">
                <div className={`${getColor(testData.get("score"))} w-full h-full rounded-full`} style={{ width: calculateScorePercentage(testData.get("score")) }}></div>
              </div>
              {(testData.has("image_id") || testData.has("external_link")) && (
                <div>
                  {/* <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                  <label>accept</label> */}
                  <button className="ml-4 mt-5 bg-gray-400 bg-opacity-70 hover:bg-opacity-50 text-white text-sm py-2 px-4 rounded-full shadow flex items-center justify-center w-12 h-12" onClick={() => {
                    setIsAccepted(!isAccepted);
                    updateTestData("score", (isAccepted ? 1 : 0));
                  }}>accept</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestCard;
