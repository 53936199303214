import React, { useCallback, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { initialUseCaseConfig, initialActiveUseCase } from './config';
import { getColor, calculateScorePercentage } from './utils';
import RiskDimensionCard from './RiskDimensionCard';
import Login from './Login';
import ClassificationModal from './ClassificationModal';
import ChatbotModal from './ChatbotModal';

function App() {
  const survey_url = process.env.REACT_APP_SURVEY_URL || 'https://df9cghf80hc.typeform.com/to/CUtuAW0y#anbieter=xxxxx';
  const chatbot_url = process.env.REACT_APP_CHATBOT_URL || 'https://certifai-cockpit-dev-chatbot.azurewebsites.net';

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [useCases, setUseCases] = useState(initialUseCaseConfig);
  const [activeUseCase, setActiveUseCase] = useState(initialActiveUseCase);
  const [totalScore, setTotalScore] = useState(0);
  const [subScores, setSubScores] = useState({}); // eslint-disable-line no-unused-vars
  const [useCaseData, setUseCaseData] = useState(new Map([])); // data structure for storing the results of the tests in the different use cases, dimensions and risk areas
  const [showIframe, setShowIframe] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [viewOverview, setViewOverview] = useState(true);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  // eslint-disable-next-line
  const updateUseCase = (key, value) => {
    setUseCases(prevMap => new Map(prevMap).set(key, value));
  };

  // eslint-disable-next-line
  function updateUseCaseScore(dimensionName, score) {
    setSubScores(prevScores => {
      const newScores = { ...prevScores, [dimensionName]: score };
      const sumScores = Object.values(newScores).reduce((a, b) => a + (b || 0), 0); // sum all elements (use 0 if undefines)
      const avgScore = sumScores / Object.keys(newScores).length; // Calculate the average
      setTotalScore(avgScore);
      return newScores;
    });
  };

  const propagateDimensionData = useCallback((dimensionName, dimensionData) => { // function for sub-components to update the data
    updateUseCaseScore(dimensionName, dimensionData.get("score"));
    setUseCaseData(prevState => {
      const newState = new Map(prevState);
      const subState = new Map(newState.get(activeUseCase) || []);
      subState.set(dimensionName, dimensionData);
      subState.set("score", totalScore);
      newState.set(activeUseCase, subState);
      return newState;
    });
  }, [activeUseCase, totalScore]);

  const renderUseCase = (useCaseName) => {
    const useCase = useCases.get(useCaseName);
    const singelUseCaseData = useCaseData.has(useCaseName) ? useCaseData.get(useCaseName) : new Map([]);
    return <div>
      {useCase && useCase.risk_dimensions ? (
        useCase.risk_dimensions.map((risk_dimension) => (
          <RiskDimensionCard
            key={useCaseName + "_" + risk_dimension.name}
            id={useCaseName + "_" + risk_dimension.name}
            dimensionName={risk_dimension.name}
            risk_areas={risk_dimension.risk_areas}
            propagateDimensionData={propagateDimensionData}
            initialDimensionData={singelUseCaseData.has(risk_dimension.name) ? singelUseCaseData.get(risk_dimension.name) : new Map([])}
          />
        ))
      ) : <div>Use case {useCaseName} not found</div>
      }
    </div>
  };

  function IframeComponent({ url }) {
    if (!showIframe) {
      return null;
    }
    return (
      <div className="iframe-container">
        <button onClick={() => setShowIframe(false)} className="close-button">X</button>
        <iframe src={url} className="fullsize-iframe" title="External Content"></iframe>
      </div>
    );
  }

  const handleSurveyClick = () => {
    setShowIframe(true);
    setShowModal(false);
  };

  const handleChatbotClick = () => {
    window.open(chatbot_url, '_blank');
    setShowModal(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const renderDashboard = () => {
    return (
      <div className="dashboard">
        <h1 className="dashboard-title">AI Use-Case Inventory</h1>
        <div className="use-case-list">
          {Array.from(useCases).map(([key, value]) => (
            <div key={key} className="use-case-card" onClick={() => { setActiveUseCase(key); setViewOverview(false); }}>
              <h2 className="use-case-name">{key}</h2>
              <p className="use-case-risk">Risk Dimensions: {value.risk_dimensions.length}</p>
              <p className="use-case-risk-classification">Risk Classification: {value.risk_classification}</p>
              <div className="use-case-compliance">
                <span>Compliance: </span>
                <span className="compliance-indicator compliant">✔</span> {/* or .non-compliant */}
              </div>
            </div>
          ))}
          <div className="add-use-case">
            <button className="add-use-case-button" onClick={() => setShowModal(true)}>
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (!isLoggedIn) {
    return <Login className="login-form" onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="App">
      <div className="bg-gray-800 p-4 flex justify-between items-center">
        <img src={logo} alt="logo" className="w-30 h-10" />
        {/* Score indicator in the header */}
        <div className="flex-1 flex items-center justify-end pr-8">
          <div style={{ marginRight: '10px' }} className="text-white">{`Progress: ${Math.ceil(totalScore * 100)}%`}</div>
          <div className="w-32 h-2 rounded-full overflow-hidden mr-4 bg-gray-200">
            <div className={`${getColor(totalScore)} h-full rounded-full`} style={{ width: calculateScorePercentage(totalScore) }}></div>
          </div>
        </div>
        <div className="flex space-x-4">
          <button className="text-white bg-gray-500 hover:bg-gray-700 py-2 px-4 rounded">Run Tests</button>
          <button className="text-white bg-gray-500 hover:bg-gray-700 py-2 px-4 rounded">Generate Report</button>
        </div>
      </div>
      <div className="flex"> {/* This flex container will ensure that sidebar and main content are displayed side by side */}
        {/* Sidebar */}
        {!viewOverview && (
          <div className="sidebar bg-gray-800 h-screen w-64 p-4">
            <div className="text-white mb-8">
              <div className="flex flex-col items-center mb-4">
                <img src="./favicon.ico" alt="Your company logo" className="w-20 h-20 rounded-full mr-2 mb-2" />
                <span>Your company Profile</span>
              </div>
              {Array.from(useCases).map(([key, value]) => (
                <div key={key + "-button"}>{
                  <button className={`text-white hover:bg-gray-700 py-2 px-4 block w-full text-left border-b border-white ${activeUseCase === key ? 'font-bold bg-gray-700' : ''}`} onClick={() => { setActiveUseCase(key); setViewOverview(false); }}>{key}</button>}
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-4">
              <button className="bg-white bg-opacity-70 hover:bg-opacity-50 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded-full shadow flex items-center justify-center w-12 h-12" onClick={() => setShowModal(true)}>
                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                </svg>
              </button>
            </div>
          </div>
        )}
        {/* Main content */}
        <main className="flex-1 p-8">
          {viewOverview ? renderDashboard() : renderUseCase(activeUseCase)}
        </main>
      </div>
      <ClassificationModal
        isVisible={showModal}
        onClose={handleModalClose}
        onSurveyClick={handleSurveyClick}
        onChatbotClick={handleChatbotClick}
      />
      {showIframe && (
        <div>
          <IframeComponent url={survey_url} />
        </div>
      )}
      <ChatbotModal />
    </div>
  );
}

export default App;
